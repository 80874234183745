import { Bond } from '../types/Bond';
import { supabase } from '../config/supabase';

export class BondDataService {
  private subscribers: ((bonds: Bond[]) => void)[] = [];
  private subscription: any;

  async connect() {
    console.log('BondDataService: Connecting...');
    try {
      // Initial fetch of bond data with explicit columns
      console.log('BondDataService: Fetching initial bond data...');
      const { data, error } = await supabase
        .from('bonds')
        .select(`
          id,
          name,
          price,
          yield,
          duration,
          rating,
          maturity,
          created_at
        `)
        .order('name');

      if (error) {
        console.error('BondDataService: Error fetching bonds:', error);
        throw error;
      }

      // Log the raw response
      console.log('BondDataService: Raw Supabase response:', { data, error });

      if (!data) {
        console.warn('BondDataService: No data received from Supabase');
        this.notifySubscribers([]);
        return;
      }

      if (data.length === 0) {
        console.warn('BondDataService: Empty bonds array received from Supabase');
      }

      console.log('BondDataService: Initial bonds data received:', data);
      const bondsWithChange = data.map(bond => ({
        ...bond,
        weeklyYieldChange: 0
      }));
      this.notifySubscribers(bondsWithChange);

      // Set up real-time subscription
      console.log('BondDataService: Setting up real-time subscription...');
      this.subscription = supabase
        .channel('custom-all-channel')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'bonds'
          },
          async (payload) => {
            console.log('BondDataService: Real-time update received:', payload);
            
            // Fetch all bonds again to ensure consistent state
            const { data: updatedBonds, error: refreshError } = await supabase
              .from('bonds')
              .select(`
                id,
                name,
                price,
                yield,
                duration,
                rating,
                maturity,
                created_at
              `)
              .order('name');

            if (refreshError) {
              console.error('BondDataService: Error refreshing bonds:', refreshError);
              return;
            }

            if (!updatedBonds) {
              console.warn('BondDataService: No data received during refresh');
              return;
            }

            console.log('BondDataService: Updated bonds data:', updatedBonds);
            const refreshedBondsWithChange = updatedBonds.map(bond => ({
              ...bond,
              weeklyYieldChange: 0
            }));
            this.notifySubscribers(refreshedBondsWithChange);
          }
        )
        .subscribe((status) => {
          console.log('BondDataService: Subscription status:', status);
        });

      return this.subscription;
    } catch (err) {
      console.error('BondDataService: Error in connect:', err);
      throw err;
    }
  }

  subscribe(callback: (bonds: Bond[]) => void) {
    console.log('BondDataService: New subscriber added');
    this.subscribers.push(callback);
    return () => {
      console.log('BondDataService: Subscriber removed');
      this.subscribers = this.subscribers.filter(sub => sub !== callback);
    };
  }

  private notifySubscribers(bondData: Bond[]) {
    console.log('BondDataService: Notifying subscribers with data:', bondData);
    this.subscribers.forEach(callback => callback(bondData));
  }

  disconnect() {
    console.log('BondDataService: Disconnecting...');
    if (this.subscription) {
      this.subscription.unsubscribe();
      console.log('BondDataService: Unsubscribed from real-time updates');
    }
  }
} 
import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { Bond } from './types/Bond';
import { BondDataService } from './services/BondDataService';
import BondTable from './components/BondTable';
import BondPerformanceChart from './components/BondPerformanceChart';

const bondService = new BondDataService();

// Mock yield changes for demonstration (replace with real data later)
const mockYieldChanges: { [key: string]: number } = {
  'CorporateBond1': -1.2,
  'CorporateBond2': 0.8,
  'GovernmentBond1': -0.9,
  'GovernmentBond2': -0.5,
  'MunicipalBond1': 0.3,
};

function App() {
  const [bonds, setBonds] = useState<Bond[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log('App component is rendering!');
    const initialize = async () => {
      try {
        const unsubscribe = bondService.subscribe((newBonds) => {
          console.log('Received new bonds data:', newBonds);
          // Add yield changes to bonds
          const bondsWithChanges = newBonds.map(bond => ({
            ...bond,
            yieldChange: mockYieldChanges[bond.name] || 0,
          }));
          setBonds(bondsWithChanges);
          setLoading(false);
        });

        await bondService.connect();

        return () => {
          unsubscribe();
          bondService.disconnect();
        };
      } catch (err) {
        console.error('Error initializing bond service:', err);
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
        setLoading(false);
      }
    };

    initialize();
  }, []);

  if (loading) {
    return (
      <Container>
        <Typography variant="h6">Loading bond data...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h6" color="error">Error: {error}</Typography>
      </Container>
    );
  }

  // Sort bonds by yield change (ensure we have numbers for comparison)
  const bondsWithDefaultChange = bonds.map(bond => ({
    ...bond,
    yieldChange: typeof bond.yieldChange === 'number' ? bond.yieldChange : 0
  }));
  
  const sortedByPerformance = [...bondsWithDefaultChange].sort((a, b) => a.yieldChange - b.yieldChange);
  const bestPerforming = sortedByPerformance[0];
  const worstPerforming = sortedByPerformance[sortedByPerformance.length - 1];
  
  // Get top 3 best and bottom 2 worst performing bonds
  const topThree = sortedByPerformance.slice(0, 3);
  const bottomTwo = sortedByPerformance.slice(-2);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
        Bond Performance Dashboard
      </Typography>
      
      <Grid container spacing={3}>
        {/* Performance Charts */}
        <Grid item xs={12} md={6}>
          <div style={{ height: '400px' }}>
            <BondPerformanceChart 
              bond={bestPerforming}
              title="Best Performing Bond"
              performanceLabel="Yield Decrease"
              yieldChange={bestPerforming.yieldChange}
            />
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div style={{ height: '400px' }}>
            <BondPerformanceChart 
              bond={worstPerforming}
              title="Worst Performing Bond"
              performanceLabel="Yield Increase"
              yieldChange={worstPerforming.yieldChange}
            />
          </div>
        </Grid>

        {/* Performance Tables */}
        <Grid item xs={12}>
          <div style={{ height: '300px' }}>
            <BondTable 
              bonds={topThree}
              title="Top 3 Best Performing Bonds"
              performanceType="best"
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div style={{ height: '250px' }}>
            <BondTable 
              bonds={bottomTwo}
              title="Bottom 2 Worst Performing Bonds"
              performanceType="worst"
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default App; 
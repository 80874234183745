import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Bond } from '../types/Bond';
import { Paper, Typography } from '@mui/material';

interface Props {
  bonds: Bond[];
  title: string;
  performanceType: 'best' | 'worst';
}

const BondTable: React.FC<Props> = ({ bonds, title, performanceType }) => {
  const columns: GridColDef[] = [
    { 
      field: 'name', 
      headerName: 'Bond Name', 
      flex: 1,
      minWidth: 180,
    },
    { 
      field: 'yield', 
      headerName: 'Current Yield', 
      flex: 1,
      minWidth: 130,
      valueFormatter: (params) => `${params.value.toFixed(2)}%`,
    },
    { 
      field: 'yieldChange',
      headerName: '3-Month Change',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => `${params.value > 0 ? '+' : ''}${params.value.toFixed(2)}%`,
      cellClassName: (params) => 
        params.value > 0 ? 'text-red-600' : 'text-green-600',
    },
    { 
      field: 'rating', 
      headerName: 'Rating', 
      flex: 1,
      minWidth: 100,
    },
  ];

  return (
    <Paper 
      elevation={2} 
      sx={{ 
        height: '100%',
        p: 2,
        bgcolor: performanceType === 'best' ? 'rgba(34, 197, 94, 0.05)' : 'rgba(239, 68, 68, 0.05)',
        border: 1,
        borderColor: performanceType === 'best' ? 'rgb(34, 197, 94)' : 'rgb(239, 68, 68)',
        borderRadius: 2,
      }}
    >
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{ 
          color: performanceType === 'best' ? 'rgb(34, 197, 94)' : 'rgb(239, 68, 68)',
          fontWeight: 'bold',
        }}
      >
        {title}
      </Typography>
      <div style={{ height: 'calc(100% - 40px)', width: '100%' }}>
        <DataGrid
          rows={bonds}
          columns={columns}
          hideFooterPagination
          hideFooter
          disableRowSelectionOnClick
          sx={{
            border: 'none',
            '& .MuiDataGrid-cell': {
              fontSize: '0.95rem',
            },
            '& .text-red-600': {
              color: 'rgb(239, 68, 68)',
              fontWeight: 'bold',
            },
            '& .text-green-600': {
              color: 'rgb(34, 197, 94)',
              fontWeight: 'bold',
            },
          }}
        />
      </div>
    </Paper>
  );
};

export default BondTable; 
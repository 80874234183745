import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';
import { Bond } from '../types/Bond';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  bond: Bond;
  title: string;
  performanceLabel: string;
  yieldChange: number;
}

const BondPerformanceChart: React.FC<Props> = ({ bond, title, performanceLabel, yieldChange }) => {
  // Generate mock historical data for demonstration
  const generateHistoricalData = () => {
    const dates = [];
    const yields = [];
    const currentDate = new Date();
    const startYield = bond.yield - yieldChange; // Starting yield 3 months ago
    
    for (let i = 90; i >= 0; i--) {
      const date = new Date(currentDate);
      date.setDate(date.getDate() - i);
      dates.push(date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }));
      
      // Create a smooth progression from start yield to current yield
      const progress = (90 - i) / 90;
      const currentPointYield = startYield + (yieldChange * progress);
      // Add some random noise to make it look more realistic
      const noise = (Math.random() - 0.5) * 0.1;
      yields.push(currentPointYield + noise);
    }
    
    return { dates, yields };
  };

  const { dates, yields } = generateHistoricalData();

  const data = {
    labels: dates,
    datasets: [
      {
        label: bond.name,
        data: yields,
        borderColor: yieldChange > 0 ? 'rgb(239, 68, 68)' : 'rgb(34, 197, 94)',
        backgroundColor: yieldChange > 0 ? 'rgba(239, 68, 68, 0.1)' : 'rgba(34, 197, 94, 0.1)',
        tension: 0.3,
        fill: true,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            if (typeof context.parsed.y === 'number') {
              return `Yield: ${context.parsed.y.toFixed(2)}%`;
            }
            return `Yield: ${context.parsed.y}`;
          },
        },
      },
      title: {
        display: true,
        text: [
          title,
          `${performanceLabel}: ${Math.abs(yieldChange).toFixed(2)}%`,
        ],
        color: yieldChange > 0 ? 'rgb(239, 68, 68)' : 'rgb(34, 197, 94)',
        font: {
          size: 14,
          weight: 'bold',
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 8,
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Yield (%)',
        },
        ticks: {
          callback: function(value) {
            if (typeof value === 'number') {
              return `${value.toFixed(2)}%`;
            }
            return value;
          },
        },
      },
    },
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default BondPerformanceChart; 